<div class="nav-container">
  <h1 class="title">Beauté au québec.fr</h1>
  <div class="navigation-bar">
    <div class="links-container">
      @for (link of navigationBarPages; track link) {
        <p
          class="text"
          [ngClass]="{ active: link.active, carteLink: link.url === '/map' }"
          (click)="navigateTo(link.url)"
        >
          {{ link.pageName }}
          @if (
            link.url === "/map" &&
            isNotificationPastilleDisplayed &&
            notificationsCounter() > 0
          ) {
            <div class="notificationPastille">
              <p class="text">
                {{ notificationsCounter()
                }}{{ notificationsCounter() === 99 ? "+" : "" }}
              </p>
            </div>
          }
        </p>
      }
    </div>
    <div class="separator-container">
      <div class="separator"></div>
    </div>
  </div>
  <mat-icon class="plane-icon" svgIcon="plane"></mat-icon>
</div>
