import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthHttpService } from './core/services/auth-http.service';
import { map } from 'rxjs/operators';
import { UserService } from './core/services/user.service';

export const loginGuard: CanActivateFn = (route, state) => {
  const authHttpService = inject(AuthHttpService);
  const userService = inject(UserService);
  const router = inject(Router);

  return authHttpService.isUserAuthenticated().pipe(
    map((user) => {
      if (user === null) {
        userService.setUser(null);
        router.navigate(['login']);
        return false;
      }
      userService.setUser(user);
      authHttpService.setIsUserConnected(true);
      return true;
    }),
  );
};
